import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import Error from './pages/Error/Error';
import { SkeletonTheme } from 'react-loading-skeleton';

const history = createBrowserHistory();

function Fallback({ error }) {
    return (
        <Error
            description="Please reload your app"
            code="Something went wrong"
            style={{ fontSize: '3rem' }}
        />
    );
}

Sentry.init({
    dsn: 'https://9dd49da4f2e6e0643bbde3f84c4956f0@o4507871029231616.ingest.de.sentry.io/4507871043059792',
    integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    sampleRate: parseFloat(`${process.env.REACT_APP_SAMPLE_RATE}`),
    // Tracing
    tracesSampleRate: parseFloat(`${process.env.REACT_APP_TRACES_SAMPLE_RATE}`), //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', `${process.env.REACT_APP_LINK}`],
    // Session Replay

    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
        const error = hint.originalException;
        // Check if the event contains the specific error message
        const ignoredErrors = [
            '[TON_CONNECT_SDK_ERROR] TonConnectError Operation aborted',
            '[TON_CONNECT_SDK_ERROR] UserRejectsError: User rejects the action in the wallet. Wallet declined the request',
            '[TON_CONNECT_SDK_ERROR] v Operation aborted',
            '[TON_CONNECT_SDK_ERROR] v Resource recreation was aborted by a new resource creation',
            '[TON_CONNECT_SDK_ERROR] v Aborted after attempts 1',
        ];
        if (
            error &&
            error.message &&
            ignoredErrors.some((err) => error.message.match(err))
        ) {
            return null;
        } else {
            return event;
        }
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Sentry.ErrorBoundary FallbackComponent={Fallback}>
        <Provider store={store}>
            <TonConnectUIProvider
                manifestUrl={`${process.env.REACT_APP_LINK}/tonconnect-manifest.json`}
            >
                <SkeletonTheme baseColor="#ebebeb00" highlightColor="#f5f5f5">
                    <App />
                </SkeletonTheme>
            </TonConnectUIProvider>
        </Provider>
    </Sentry.ErrorBoundary>
);

reportWebVitals();
