import React from 'react';
import './TaskContainer.scss';
import Skeleton from 'react-loading-skeleton';

const TaskContainer = (props) => {


    return (
        <div className={`task-container ${props.className}`}>
            <label className="task-label">{props.label || <Skeleton />}</label>
            <div className="task-carousel">
                <div className="task-grid">
                    {props.children || <Skeleton />}
                </div>
            </div>
        </div>
    );
};

export default TaskContainer;
