import React, { useEffect, useState } from 'react';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import HomeIcon from '../../assets/components/Navbar/HomeIcon.svg';
import MissionIcon from '../../assets/components/Navbar/MissionsIcon.svg';
import BoostIcon from '../../assets/components/Navbar/BoostIcon.svg';
import FriendsIcon from '../../assets/components/Navbar/FriendsIcon.svg';
import AppIcon from '../../assets/components/Navbar/AppsIcon.svg';
import HomeActiveIcon from '../../assets/components/Navbar/HomeActiveIcon.svg';
import MissionActiveIcon from '../../assets/components/Navbar/MissionsActiveIcon.svg';
import BoostActiveIcon from '../../assets/components/Navbar/BoostActiveIcon.svg';
import FriendsActiveIcon from '../../assets/components/Navbar/FriendsActiveIcon.svg';
import AppActiveIcon from '../../assets/components/Navbar/AppsActiveIcon.svg';
import SoonIcon from '../../assets/components/Navbar/SoonIcon.svg';
import Button from '../../components/Button/Button';
import './Navbar.scss';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const totalFriends = useSelector((state) => state.common.friends);
    const location = useLocation();
    const [activePosition, setActivePosition] = useState({ left: 0, width: 0 });

    useEffect(() => {
        const activeBtn = document.querySelector('.menu-btn.active');
        const parentContainer = document.querySelector('.navbar-group');
        if (activeBtn && parentContainer) {
            const menuBtnWidth = activeBtn.offsetWidth; // Stable width
            const activeBtnRect = activeBtn.getBoundingClientRect();
            const parentRect = parentContainer.getBoundingClientRect();
            const left = activeBtnRect.left - parentRect.left;

            setActivePosition({ left, width: menuBtnWidth });
        }
    }, [location]);

    return (
        <div className="navbar">
            <div className="navbar-container">
                <img className={'soon-icon'} src={SoonIcon} alt="soon icon" />
                <div className="navbar-group">
                    <div
                        className="menu-active-background"
                        style={{
                            left: `${activePosition.left}px`,
                            width: `${activePosition.width}px`,
                            transition: 'left 0.1s ease, width 0.1s ease',
                        }}
                    />
                    <Button
                        className={`menu-btn rotating-border--google`}
                        icon={
                            <SuspenseImage
                                src={HomeIcon}
                                alt="home icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        activeIcon={
                            <SuspenseImage
                                src={HomeActiveIcon}
                                alt="home active icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        to="/"
                        undertext="true"
                    >
                        <label className="navbar-label">Home</label>
                    </Button>
                    <Button
                        className={`menu-btn`}
                        icon={
                            <SuspenseImage
                                src={MissionIcon}
                                alt="mission icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        activeIcon={
                            <SuspenseImage
                                src={MissionActiveIcon}
                                alt="mission active icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        to="/missions"
                        undertext="true"
                    >
                        <label className="navbar-label">Mission</label>
                    </Button>
                    <Button
                        className={`menu-btn`}
                        icon={
                            <SuspenseImage
                                src={BoostIcon}
                                alt="boost icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        activeIcon={
                            <SuspenseImage
                                src={BoostActiveIcon}
                                alt="boost active icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        to="/boost"
                        undertext="true"
                    >
                        <label className="navbar-label">Boost</label>
                    </Button>
                    <Button
                        className={`menu-btn`}
                        icon={
                            <SuspenseImage
                                src={FriendsIcon}
                                alt="friends icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        activeIcon={
                            <SuspenseImage
                                src={FriendsActiveIcon}
                                alt="friends active icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        to="/friends"
                        undertext="true"
                        notification={
                            <>
                                {totalFriends.length === 0 && (
                                    <div className="navbar-friend-notification"></div>
                                )}
                            </>
                        }
                    >
                        <label className="navbar-label">Friends</label>
                    </Button>

                    <Button
                        className="menu-btn"
                        icon={
                            <SuspenseImage
                                className={'apps-icon'}
                                src={AppIcon}
                                alt="apps icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        activeIcon={
                            <SuspenseImage
                                src={AppActiveIcon}
                                alt="apps active icon"
                                height="3rem"
                                width="3rem"
                            />
                        }
                        style={{
                            WebkitFilter: 'grayscale(100%)',
                            filter: 'grayscale(100%)',
                        }}
                        undertext="true"
                        disabled={true}
                    >
                        <label className="navbar-label">Apps</label>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
