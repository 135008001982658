import React from 'react';
import Button from '../../components/Button/Button';
import autoCollectorIcon from '../../assets/components/AutoCollectorIcon/AutoCollectorIcon.svg';
import './AutoCollectoTimeoutModal.scss';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import starIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import Skeleton from 'react-loading-skeleton';

const AutoCollectoTimeoutModal = (props) => {
    
    return (
        <div className="collector-timeout-container">
            <div className="collector-timeout-img-container">
                <img
                    className="collector-timeout-img"
                    src={autoCollectorIcon}
                    alt="auto collector icon"
                ></img>
            </div>
            <div className="collector-timeout-info">
                <span className="title">Auto-Collector</span>
                <span className="description">
                    While you were away, the bot successfully made three
                    collections.
                </span>
                <span className="description">Now it can continue mining!</span>
            </div>
            {props.amount && (
                <span className="storage-info">
                    <SuspenseImage
                        className="counter-gem"
                        alt="eni-dimond"
                        src={starIcon}
                        width="2rem"
                        height="2rem"
                        cricle="true"
                    ></SuspenseImage>
                    <span className="balance-coins">
                        {Number(50.515135).toFixed(4) || (
                            <Skeleton width="10rem" />
                        )}
                    </span>
                </span>
            )}
            {props.daysLeft && (
                <div className="collector-timeout-timer">
                    <span>8 Days Left</span>
                </div>
            )}
            <div className="timeout-continue-btn">
                <Button className="button continue glowing" onClick={() => {props.onClose()}}>Got it!</Button>
            </div>
        </div>
    );
};

export default AutoCollectoTimeoutModal;
