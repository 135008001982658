import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import Button from '../Button/Button';
import toast from 'react-hot-toast';
import leaveButtonIcon from '../../assets/components/LeaveButtonIcon/LeaveButton.svg';
import './TonDisconnect.scss';

const TonDisconnect = () => {
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();

    if (!wallet) {
        return null;
    }

    const onDisconnect = async () => {
        await tonConnectUI.disconnect();
        toast.success('Wallet removed!');
    };

    return (
        <div className="ton-disconnect-container">
            <Button className="ton-disconnect-button" onClick={onDisconnect}>
                <img className='leave-icon' src={leaveButtonIcon} alt="leave icon"></img>
            </Button>
        </div>
    );
};

export default TonDisconnect;
