import React, { useState, useEffect } from "react";

const useProgressPercent = (createdAtTime, finishedAtTime) => {
    const [percentComplete, setPercentComplete] = useState(0);

    useEffect(() => {
        const calculateProgress = () => {
            const createdAt = new Date(createdAtTime).getTime();
            const finishedAt = new Date(finishedAtTime).getTime();
            const now = new Date().getTime();

            if (createdAt > finishedAt) {
                setPercentComplete(100);
                return;
            }

            const totalDuration = finishedAt - createdAt;
            const elapsedTime = now - createdAt;
            const percent = (elapsedTime / totalDuration) * 100;

            setPercentComplete(Math.min(Math.max(percent, 0), 100));
        };

        calculateProgress(); // Calculate immediately
        const interval = setInterval(calculateProgress, 1000); // Update every second

        return () => clearInterval(interval);
    }, [createdAtTime, finishedAtTime]);

    return percentComplete;
};

export default useProgressPercent;