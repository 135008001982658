import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    sendCompleteTask,
    sendCompleteAchievement,
    getTonInfo,
} from '../../tools/requests.js';
import { getIconForTask } from '../../assets/components/TaskAchievementPack/index.js';
import Button from '../../components/Button/Button';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import Task from '../../components/Task/Task';
import TaskContainer from '../../components/TaskContainer/TaskContainer';
import Achievement from '../../components/Achievement/Achievement';
import AchievementContainer from '../../components/AchievementContainer/AchievementContainer';
import CombinedAchievementContainer from '../../components/CombinedAchievementContainer/CombinedAchievementContainer';
import rigelWhiteIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import rigelGradientIcon from '../../assets/components/StarIcon/RigelLogo.svg';
import rigelAchievementIcon from '../../assets/components/MissionIcon/RigelAchivLogo.svg';
import noImgUserIcon from '../../assets/components/UserAvatar/NoImgUserIcon.png';
import noImgIcon from '../../assets/components/NoImgIcon/NoImgIcon.png';
import './Missions.scss';
import Popup from '../../components/Popup/Popup.jsx';
import PaymentMethodModal from '../../modals/PaymentMethodModal/PaymentMethodModal.jsx';
import { motion } from 'framer-motion';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage.jsx';
import Skeleton from 'react-loading-skeleton';

const Missions = () => {
    const dispatch = useDispatch();

    const tg = window.Telegram.WebApp;
    const initData = tg.initData;

    const userInfo = useSelector((state) => state.common.userInfo);
    const userTasks = useSelector((state) => state.common.tasks);
    const achieves = useSelector((state) => state.common.achieves);

    const [activeButton, setActiveButton] = useState('Tasks');
    const [openModal, setOpenModal] = useState(false);
    const [newAchievements, setNewAchievements] = useState([]);
    const [isTxCompleted, setTxCompleted] = useState(false);
    const [currentTaskId, setCurrentTaskId] = useState(null);

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const handleCompleteTask = async (id) => {
        const result = await sendCompleteTask(
            initData,
            userInfo,
            dispatch,
            id,
            false
        );
        return result;
    };

    const handleCompleteAchievement = async (id) => {
        const result = await sendCompleteAchievement(
            initData,
            userInfo,
            dispatch,
            id
        );
        return result;
    };

    useEffect(() => {
        getTonInfo(initData, dispatch);
    }, [initData, dispatch]);

    useEffect(() => {
        const transformedAchievements = achieves.map((achievementGroup) => ({
            label: achievementGroup.name,
            description: '',
            currentValue: achievementGroup.progress,
            items: achievementGroup.steps.map((step) => ({
                id: step.id,
                reward: step.reward,
                description: step.name,
                completed: step.completed,
                canBeCompleted: step.can_be_completed,
            })),
        }));
        setNewAchievements(transformedAchievements);
    }, [achieves]);

    return (
        <Wrapper>
            <motion.div
                className="mission-container"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <div className="user-info">
                    <SuspenseImage
                        className="user-avatar"
                        src={userInfo.photo_url || noImgUserIcon}
                        alt="user avatar"
                        width="8rem"
                        height="8rem"
                    ></SuspenseImage>
                    <div className="user-data">
                        <span className="user-name">
                            {userInfo?.first_name || <Skeleton />}
                        </span>
                        <div className="user-coins">
                            <SuspenseImage
                                className="diamond"
                                src={rigelWhiteIcon}
                                alt="diamond icon"
                                style={{ width: '2rem' }}
                                width="2rem"
                                height="2rem"
                            ></SuspenseImage>
                            <span className="coins">
                                {Number(userInfo?.balance).toFixed(4) || (
                                    <Skeleton />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="list-sort-group">
                    <Button
                        className={`sort-btn ${
                            activeButton === 'Tasks' ? 'active' : ''
                        }`}
                        onClick={() => handleButtonClick('Tasks')}
                    >
                        Tasks
                    </Button>
                    <Button
                        className={`sort-btn ${
                            activeButton === 'achievements' ? 'active' : ''
                        }`}
                        onClick={() => handleButtonClick('achievements')}
                    >
                        Achievements
                    </Button>
                </div>

                {activeButton === 'Tasks' && (
                    <div className="eni-tasks-block">
                        <div className="eni-tasks">
                            {Object.keys(userTasks).map((category) => (
                                <TaskContainer
                                    key={category}
                                    label={category}
                                    className={`${category.toLowerCase()}-task-group`}
                                >
                                    {userTasks[category].map((task) => (
                                        <Task
                                            id={task.id}
                                            key={task.id}
                                            icon={
                                                task.icon
                                                    ? task.icon
                                                    : noImgIcon
                                            }
                                            name={task.name}
                                            type={task.type}
                                            status={task.status}
                                            options={task.options}
                                            reward={task.reward}
                                            completed={task.completed}
                                            userInfo={userInfo}
                                            setOpenModal={setOpenModal}
                                            setCurrentTaskId={setCurrentTaskId}
                                            handleCompleteTask={
                                                handleCompleteTask
                                            }
                                            isTxCompleted={isTxCompleted}
                                            setTxCompleted={setTxCompleted}
                                            className="task"
                                            userTasks={userTasks}
                                        />
                                    ))}
                                </TaskContainer>
                            ))}
                        </div>
                    </div>
                )}

                {activeButton === 'achievements' && (
                    <div className="achievements-block">
                        {newAchievements.map((achievement, index) =>
                            achievement.double ? (
                                <CombinedAchievementContainer
                                    key={index}
                                    className={`${achievement.label
                                        .toLowerCase()
                                        .replace(' ', '-')}-group`}
                                    subImgOne={''}
                                    subLabelOne={achievement.subLabelOne}
                                    subDescriptionOne={
                                        achievement.subDescriptionOne
                                    }
                                    currentSubValueOne={
                                        achievement.currentSubValueOne
                                    }
                                    subImgTwo={''}
                                    subLabelTwo={achievement.subLabelTwo}
                                    subDescriptionTwo={
                                        achievement.subDescriptionTwo
                                    }
                                    currentSubValueTwo={
                                        achievement.currentSubValueTwo
                                    }
                                    achievementsOne={achievement.subItemsOne?.map(
                                        (item, index) => (
                                            <Achievement
                                                key={index}
                                                reward={item.reward}
                                                description={
                                                    <span>
                                                        <img
                                                            src={''}
                                                            alt="invite"
                                                        />
                                                        {item.description}
                                                    </span>
                                                }
                                                className={`achievement-${index}`}
                                                icon={rigelAchievementIcon}
                                                handleCompleteAchievement={
                                                    handleCompleteAchievement
                                                }
                                            />
                                        )
                                    )}
                                    achievementsTwo={achievement.subItemsTwo?.map(
                                        (item, index) => (
                                            <Achievement
                                                key={index}
                                                reward={item.reward}
                                                description={
                                                    <span>
                                                        <img
                                                            src={''}
                                                            alt="invite"
                                                        />
                                                        {item.description}
                                                    </span>
                                                }
                                                className={`achievement-${index}`}
                                                icon={rigelGradientIcon}
                                            />
                                        )
                                    )}
                                />
                            ) : (
                                <AchievementContainer
                                    key={index}
                                    className={`${achievement.label
                                        .toLowerCase()
                                        .replace(' ', '-')}-group`}
                                    label={achievement.label}
                                    description={achievement.description}
                                    currentValue={achievement.currentValue}
                                >
                                    {achievement.items.map(
                                        (item, itemIndex) => (
                                            <Achievement
                                                id={item.id}
                                                key={itemIndex}
                                                reward={item.reward}
                                                icon={rigelAchievementIcon}
                                                description={item.description}
                                                className={`achievement-${itemIndex}`}
                                                completed={item.completed}
                                                canBeCompleted={
                                                    item.canBeCompleted
                                                }
                                                handleCompleteAchievement={
                                                    handleCompleteAchievement
                                                }
                                            />
                                        )
                                    )}
                                </AchievementContainer>
                            )
                        )}
                    </div>
                )}
                <Popup
                    title="Choose payment method"
                    className="payment-popup"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    style={{ zIndex: 1000 }}
                >
                    {
                        <PaymentMethodModal
                            currentTaskId={currentTaskId}
                            userInfo={userInfo}
                            setTxCompleted={setTxCompleted}
                            onClose={() => setOpenModal(false)}
                        />
                    }
                </Popup>
            </motion.div>
        </Wrapper>
    );
};

export default Missions;
