import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InternetConnection from '../../pages/InternetConnection/InternetConnection';
import './Wrapper.scss';

const Wrapper = (props) => {
    let tg = window.Telegram.WebApp;
    const viewportHeight = useSelector((state) => state.common.viewportHeight);

    const history = useHistory();
    const location = useLocation();

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [connectionRestored, setConnectionRestored] = useState(false);

    const scrollableElementRef = useRef(null);

    useEffect(() => {
        const ensureDocumentIsScrollable = () => {
            const isScrollable =
                document.documentElement.scrollHeight > window.innerHeight;
            if (!isScrollable) {
                document.documentElement.style.setProperty(
                    'height',
                    'calc(100vh + 1px)',
                    'important'
                );
            }
        };

        const preventCollapse = (event) => {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        };

        ensureDocumentIsScrollable();

        const scrollableElement = scrollableElementRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('touchstart', preventCollapse);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener(
                    'touchstart',
                    preventCollapse
                );
            }
        };
    }, []);

    useEffect(() => {
        const handleBackButtonClick = () => {
            if (location.pathname !== '/') {
                history.goBack();
            } else {
                tg.showPopup(
                    {
                        title: 'Leave the app?',
                        message: 'Are you sure you want to close the app?',
                        buttons: [
                            {
                                id: 'close',
                                type: 'close',
                                text: 'Close',
                            },
                            {
                                id: 'cancel',
                                type: 'cancel',
                                text: 'Cancel',
                            },
                        ],
                    },
                    (buttonId) => {
                        if (buttonId === 'close') {
                            tg.close();
                        }
                    }
                );
            }
        };
        tg.BackButton.show();
        tg.BackButton.onClick(handleBackButtonClick);
        return () => {
            tg.BackButton.offClick(handleBackButtonClick);
        };
    }, [history, location, tg]);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setConnectionRestored(true);
            setTimeout(() => setConnectionRestored(false), 2000);
            setTimeout(() => window.location.reload(), 3000);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        const checkConnection = () => {
            setIsOnline(navigator.onLine);
        };

        checkConnection();

        const interval = setInterval(checkConnection, 1000);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            clearInterval(interval);
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!tg.isExpanded) {
            tg.expand();
        }
    }, [tg]);

    return (
            <div
                className="wrapper"
                style={{ height: `${viewportHeight}` }}
                ref={scrollableElementRef}
            >
                {!isOnline && <InternetConnection type="lost" />}
                {isOnline && connectionRestored && (
                    <InternetConnection type="restored" />
                )}
                {props.children}
            </div>
    );
};

export default Wrapper;
