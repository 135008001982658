import axios from 'axios';
import log from 'loglevel';

const request_platform = async (
    checkType,
    url,
    params,
    data,
    callback,
    onError
) => {
    try {
        const headers = {
            'ngrok-skip-browser-warning': '69420',
            ...params,
        };
        let response;

        switch (checkType) {
            case 'get':
                response = await axios.get(
                    `${process.env.REACT_APP_BACKEND}${url}`,
                    {
                        headers: headers,
                    }
                );
                break;
            case 'post':
                response = await axios.post(
                    `${process.env.REACT_APP_BACKEND}${url}`,
                    data,
                    {
                        headers: headers,
                        data: data,
                    }
                );
                break;
            case 'delete':
                response = await axios.delete(
                    `${process.env.REACT_APP_BACKEND}${url}${params}`,
                    {
                        headers: headers,
                    }
                );
                break;
            default:
                throw new Error('Unsupported request type');
        }

        if (response && response.data) {
            if (callback) {
                callback(response.data);
            }
            return response.data;
        }
    } catch (err) {
        log.error(`Error! + ${err}`);
        onError(err);
    }
};

const request = {
    async get(url, params, callback, onError) {
        return await request_platform(
            'get',
            url,
            params,
            {},
            callback,
            onError
        );
    },
    async post(url, params, data, callback, onError) {
        return await request_platform(
            'post',
            url,
            params,
            data,
            callback,
            onError
        );
    },

    async delete(url, params, id, callback, onError) {
        return await request_platform(
            'delete',
            url,
            params,
            {},
            callback,
            onError
        );
    },
};

class RequestPromise {
    constructor(url, transaction, onSuccess, onError) {
        this.promise = new Promise((resolve, reject) => {
            request.post(
                url,
                transaction,
                (req) => {
                    if (onSuccess) onSuccess(req);
                    resolve(req);
                },
                (err) => {
                    if (onError) onError(err);
                    reject(err);
                }
            );
        });
    }

    then(onSuccess, onError) {
        return this.promise.then(onSuccess, onError);
    }

    catch(onError) {
        return this.promise.catch(onError);
    }
}

export { request, RequestPromise };
