
const ROUTES = {
    MAIN: '/',
    MISSIONS: '/missions',
    BOOST: '/boost',
    FRIENDS: '/friends',
    APPS: '/apps',
    GUILD: '/guild',
    GUILD_BOOST: '/guild-boost',
    GUILD_LIST: '/guild-list',
    NOT_FOUND: '*',
};
  

export { ROUTES }