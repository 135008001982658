import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import rigelIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import NoImgIcon from '../../assets/components/NoImgIcon/NoImgIcon.png';
import toast from 'react-hot-toast';
import './Task.scss';
import { useDispatch, useSelector } from 'react-redux';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';
import { setTasks } from '../../store/commonReducer';

const coinname = process.env.REACT_APP_COIN_NAME;
const appname = process.env.REACT_APP_NAME;

const Task = (props) => {
    const dispatch = useDispatch();

    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const userInfo = useSelector((state) => state.common.userInfo);

    const telegram_url = process.env.REACT_APP_TELEGRAM_BOT_URL;
    const referal_id = userInfo.referral_id ? userInfo.referral_id : null;

    const handleInviteClick = () => {
        const url = `${telegram_url}?startapp=${referal_id}`;
        const text = `Join me on ${coinname} farm at ${appname} app!`;

        const deepLink = `https://t.me/share/url?url=${encodeURIComponent(
            url
        )}&text=${encodeURIComponent(text)}`;

        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(deepLink);
        }
    };

    const getTaskByType = (type) => {
        try {
            switch (type) {
                case 'CheckIn':
                case 'Purchase':
                    return () => {
                        props.setCurrentTaskId(props.id);
                        props.setOpenModal(true);
                    };
                case 'Login':
                    return async () => {
                        const taskCategory = Object.keys(props.userTasks).find(
                            (category) =>
                                props.userTasks[category].some(
                                    (task) => task.id === props.id
                                )
                        );
                        if (taskCategory) {
                            const updatedTasks = props.userTasks[
                                taskCategory
                            ].map((task) =>
                                task.id === props.id &&
                                task.status !== 'Completed' // Check if status is not "Completed"
                                    ? { ...task, status: 'Started' }
                                    : task
                            );
                            dispatch(
                                setTasks({
                                    ...props.userTasks,
                                    [taskCategory]: updatedTasks,
                                })
                            );
                        }
                        const result = await props.handleCompleteTask(props.id);
                        if (result) {
                            toast.success(`Yay, new ${coinname} received!`);
                        } else {
                            toast.error('Please try again later');
                        }
                    };
                case 'Invite':
                    return async () => {
                        handleInviteClick();

                        setTimeout(async () => {
                            const result = await props.handleCompleteTask(
                                props.id
                            );
                            if (!result) {
                                toast.error('Please try again later');
                                const taskCategory = Object.keys(
                                    props.userTasks
                                ).find((category) =>
                                    props.userTasks[category].some(
                                        (task) => task.id === props.id
                                    )
                                );
                                if (taskCategory) {
                                    const updatedTasks = props.userTasks[
                                        taskCategory
                                    ].map((task) =>
                                        task.id === props.id &&
                                        task.status !== 'Completed' // Check if status is not "Completed"
                                            ? { ...task, status: 'Available' }
                                            : task
                                    );
                                    dispatch(
                                        setTasks({
                                            ...props.userTasks,
                                            [taskCategory]: updatedTasks,
                                        })
                                    );
                                }
                            } else {
                                toast('Confirming...\n Unlock in 15 seconds');
                                setTimeout(() => {
                                    const taskCategory = Object.keys(
                                        props.userTasks
                                    ).find((category) =>
                                        props.userTasks[category].some(
                                            (task) => task.id === props.id
                                        )
                                    );
                                    if (taskCategory) {
                                        const updatedTasks = props.userTasks[
                                            taskCategory
                                        ].map((task) =>
                                            task.id === props.id &&
                                            task.status !== 'Completed' // Check if status is not "Completed"
                                                ? {
                                                      ...task,
                                                      status: 'Available',
                                                  }
                                                : task
                                        );
                                        dispatch(
                                            setTasks({
                                                ...props.userTasks,
                                                [taskCategory]: updatedTasks,
                                            })
                                        );
                                    }
                                }, 15000);
                            }
                        }, 3000);
                    };
                case 'Channel':
                    return async () => {
                        if (window.Telegram.WebApp) {
                            const taskCategory = Object.keys(
                                props.userTasks
                            ).find((category) =>
                                props.userTasks[category].some(
                                    (task) => task.id === props.id
                                )
                            );
                            if (taskCategory) {
                                const updatedTasks = props.userTasks[
                                    taskCategory
                                ].map((task) =>
                                    task.id === props.id &&
                                    task.status !== 'Completed' // Check if status is not "Completed"
                                        ? { ...task, status: 'Started' }
                                        : task
                                );
                                dispatch(
                                    setTasks({
                                        ...props.userTasks,
                                        [taskCategory]: updatedTasks,
                                    })
                                );
                            }

                            toast('Moving to channel!');
                            window.Telegram.WebApp.openTelegramLink(
                                props?.options?.redirect_to
                            );
                            setTimeout(() => {
                                toast('Confirming...\n');
                            }, 1000);
                            setTimeout(async () => {
                                const result = await props.handleCompleteTask(
                                    props.id
                                );
                                if (!result) {
                                    toast.error('Please try again later');
                                    const taskCategory = Object.keys(
                                        props.userTasks
                                    ).find((category) =>
                                        props.userTasks[category].some(
                                            (task) => task.id === props.id
                                        )
                                    );
                                    if (taskCategory) {
                                        const updatedTasks = props.userTasks[
                                            taskCategory
                                        ].map((task) =>
                                            task.id === props.id &&
                                            task.status !== 'Completed' // Check if status is not "Completed"
                                                ? {
                                                      ...task,
                                                      status: 'Available',
                                                  }
                                                : task
                                        );
                                        dispatch(
                                            setTasks({
                                                ...props.userTasks,
                                                [taskCategory]: updatedTasks,
                                            })
                                        );
                                    }
                                }
                            }, 4000);
                        }
                    };
                case 'Redirect':
                    return async () => {
                        if (window.Telegram.WebApp) {
                            const taskCategory = Object.keys(
                                props.userTasks
                            ).find((category) =>
                                props.userTasks[category].some(
                                    (task) => task.id === props.id
                                )
                            );
                            if (taskCategory) {
                                const updatedTasks = props.userTasks[
                                    taskCategory
                                ].map((task) =>
                                    task.id === props.id &&
                                    task.status !== 'Completed' // Check if status is not "Completed"
                                        ? { ...task, status: 'Started' }
                                        : task
                                );
                                dispatch(
                                    setTasks({
                                        ...props.userTasks,
                                        [taskCategory]: updatedTasks,
                                    })
                                );
                            }

                            toast.success('Moving to external link!');
                            window.Telegram.WebApp.openLink(
                                props?.options?.redirect_to
                            );

                            setTimeout(async () => {
                                const result = await props.handleCompleteTask(
                                    props.id
                                );
                                if (result) {
                                    toast.success(
                                        `Yay, new ${coinname} received!`
                                    );
                                } else {
                                    toast.error('Please try again later');
                                }
                            }, 2000);
                        }
                    };
                default:
                    return false;
            }
        } catch (error) {
            toast.dismiss();
            toast.error('Please try again later');
            console.error('Error completing achievement:', error);
            const taskCategory = Object.keys(props.userTasks).find((category) =>
                props.userTasks[category].some((task) => task.id === props.id)
            );
            if (taskCategory) {
                const updatedTasks = props.userTasks[taskCategory].map((task) =>
                    task.id === props.id && task.status !== 'Completed' // Check if status is not "Completed"
                        ? { ...task, status: 'Available' }
                        : task
                );
                dispatch(
                    setTasks({
                        ...props.userTasks,
                        [taskCategory]: updatedTasks,
                    })
                );
            }
        }
    };

    const handleButtonClick = () => {
        if (!isCompleted) {
            const action = getTaskByType(props.type);
            if (action) {
                action();
            }
        } else {
            toast('Task already completed!');
        }
    };

    useEffect(() => {
        if (props.status === 'Available') {
            setIsLoading(false);
            setIsCompleted(false);
        } else if (props.status === 'Started') {
            setIsLoading(true);
            setIsCompleted(false);
        } else if (props.status === 'Completed') {
            setIsLoading(false);
            setIsCompleted(true);
        }
    }, [props.status]);

    return (
        <Button
            onClick={isLoading ? () => {} : handleButtonClick}
            className="task-card"
        >
            <SuspenseImage
                className="task-icon"
                src={props.icon ? props.icon : NoImgIcon}
                alt="task icon"
                width="5rem"
                height="5rem"
            />
            <div className="task">
                <span className="task-description">
                    {props.name || <Skeleton />}
                </span>
            </div>
            {isCompleted ? (
                <div className="circle-icon">
                    <FontAwesomeIcon className="icon" icon={faCheck} />
                    <span className="text">Done</span>
                </div>
            ) : (
                <div className="task-btn-container">
                    <div className="task-coin-holder">
                        <span className="task-img">
                            <SuspenseImage
                                src={rigelIcon}
                                alt="rigel icon"
                                width="1.4rem"
                                height="1.4rem"
                            ></SuspenseImage>
                        </span>
                        <span className="task-data">
                            {Number(props.reward).toFixed(4) || <Skeleton />}
                        </span>
                    </div>
                    <div className="task-font">
                        <FontAwesomeIcon
                            className="task-icon"
                            icon={faChevronRight}
                        />
                    </div>
                </div>
            )}
            {isLoading && (
                <div className="loader-mask">
                    <span className="loader"></span>
                </div>
            )}
        </Button>
    );
};

export default Task;
