import React, { useEffect, useRef, useState } from 'react';
import checkInProgressionIcon from '../../assets/components/MissionIcon/CheckInProgressIcon.svg';
import './AchievementContainer.scss';
import Skeleton from 'react-loading-skeleton';
import SuspenseImage from '../SuspenseImage/SuspenseImage';

const AchievementContainer = (props) => {
    const gridOneRef = useRef(null);

    const [currentValue, setCurrentValue] = useState(0);

   /* const scrollLeft = (gridRef) => {
        if (gridRef.current) {
            gridRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = (gridRef) => {
        if (gridRef.current) {
            gridRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };*/

    useEffect(() => {
        setCurrentValue(props.currentValue);
    }, [props.currentValue]);

    useEffect(() => {
        const makeGridDraggable = (gridRef) => {
            if (!gridRef.current) return;
            let isDown = false;
            let startX;
            let scrollLeft;

            const handleMouseDown = (e) => {
                isDown = true;
                startX = e.pageX - gridRef.current.offsetLeft;
                scrollLeft = gridRef.current.scrollLeft;
            };

            const handleMouseLeave = () => {
                isDown = false;
            };

            const handleMouseUp = () => {
                isDown = false;
            };

            const handleMouseMove = (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - gridRef.current.offsetLeft;
                const walk = (x - startX) * 3;
                gridRef.current.scrollLeft = scrollLeft - walk;
            };

            gridRef.current.addEventListener('mousedown', handleMouseDown);
            gridRef.current.addEventListener('mouseleave', handleMouseLeave);
            gridRef.current.addEventListener('mouseup', handleMouseUp);
            gridRef.current.addEventListener('mousemove', handleMouseMove);

            return () => {
                gridRef.current.removeEventListener(
                    'mousedown',
                    handleMouseDown
                );
                gridRef.current.removeEventListener(
                    'mouseleave',
                    handleMouseLeave
                );
                gridRef.current.removeEventListener('mouseup', handleMouseUp);
                gridRef.current.removeEventListener(
                    'mousemove',
                    handleMouseMove
                );
            };
        };

        makeGridDraggable(gridOneRef);
    }, []);

    return (
        <div className={`achievement-container ${props.className}`}>
            <div className="achievement-info">
                <label className="achievement-label">
                    {props.label || <Skeleton />}
                </label>
                <div className="description-block">
                    <span className="achievement-description">
                        {props.description || <Skeleton />}
                    </span>
                    <span className="achievement-value">
                        <SuspenseImage
                            src={checkInProgressionIcon}
                            width="1.6rem"
                            height="1.6rem"
                            alt="checkin progression icon"
                        ></SuspenseImage>
                        {currentValue}
                    </span>
                </div>
            </div>

            <div className="achievement-carousel">
                <div className="achievement-grid" ref={gridOneRef}>
                    {props.children || <Skeleton />}
                </div>
            </div>
        </div>
    );
};

export default AchievementContainer;
