import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import './CombinedAchievementContainer.scss';

const CombinedAchievementContainer = (props) => {
    const gridOneRef = useRef(null);
    const gridTwoRef = useRef(null);

    const scrollLeft = (gridRef) => {
        if (gridRef.current) {
            gridRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = (gridRef) => {
        if (gridRef.current) {
            gridRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const makeGridDraggable = (gridRef) => {
            if (!gridRef.current) return;
            let isDown = false;
            let startX;
            let scrollLeft;

            const handleMouseDown = (e) => {
                isDown = true;
                startX = e.pageX - gridRef.current.offsetLeft;
                scrollLeft = gridRef.current.scrollLeft;
            };

            const handleMouseLeave = () => {
                isDown = false;
            };

            const handleMouseUp = () => {
                isDown = false;
            };

            const handleMouseMove = (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - gridRef.current.offsetLeft;
                const walk = (x - startX) * 3; // Adjust scroll speed if needed
                gridRef.current.scrollLeft = scrollLeft - walk;
            };

            gridRef.current.addEventListener('mousedown', handleMouseDown);
            gridRef.current.addEventListener('mouseleave', handleMouseLeave);
            gridRef.current.addEventListener('mouseup', handleMouseUp);
            gridRef.current.addEventListener('mousemove', handleMouseMove);

            return () => {
                gridRef.current.removeEventListener(
                    'mousedown',
                    handleMouseDown
                );
                gridRef.current.removeEventListener(
                    'mouseleave',
                    handleMouseLeave
                );
                gridRef.current.removeEventListener('mouseup', handleMouseUp);
                gridRef.current.removeEventListener(
                    'mousemove',
                    handleMouseMove
                );
            };
        };

        makeGridDraggable(gridOneRef);
        makeGridDraggable(gridTwoRef);
    }, []);

    return (
        <div className={`combined-achievement-container ${props.className}`}>
            <div className="combined-achievement-info">
                <div className="combined-description">
                    <div className="combined-label-group">
                        <div className="combined-space-element"></div>
                        <div className="combined-label">
                            <img
                                className="combined-achievement-img"
                                src={props.subImgOne}
                                alt="achievement icon"
                            ></img>
                            <label className="combined-achievement-label">
                                {props.subLabelOne}
                            </label>
                        </div>
                    </div>

                    <div className="combined-description-block">
                        <span className="combined-achievement-description">
                            {props.subDescriptionOne}
                        </span>
                        <span className="combined-achievement-value">
                            {props.currentSubValueOne}
                        </span>
                    </div>
                </div>
                <div className="combined-achievement-carousel">
                    <button
                        className="carousel-button"
                        onClick={() => scrollLeft(gridOneRef)}
                    >
                        <FontAwesomeIcon
                            className="carousel-button-icon"
                            icon={faChevronLeft}
                        />
                    </button>
                    <div className="combined-achievement-grid" ref={gridOneRef}>
                        {props.achievementsOne}
                    </div>
                    <button
                        className="carousel-button"
                        onClick={() => scrollRight(gridOneRef)}
                    >
                        <FontAwesomeIcon
                            className="carousel-button-icon"
                            icon={faChevronRight}
                        />
                    </button>
                </div>
                <div className="combined-description">
                    <div className="combined-label-group">
                        <div className="combined-space-element"></div>
                        <div className="combined-label">
                            <img
                                className="combined-achievement-img"
                                src={props.subImgTwo}
                                alt="achievement icon"
                            ></img>
                            <label className="combined-achievement-label">
                                {props.subLabelTwo}
                            </label>
                        </div>
                    </div>
                    <div className="combined-description-block">
                        <span className="combined-achievement-description">
                            {props.subDescriptionTwo}
                        </span>
                        <span className="combined-achievement-value">
                            {props.currentSubValueTwo}
                        </span>
                    </div>
                </div>
                <div className="combined-achievement-carousel">
                    <button
                        className="carousel-button"
                        onClick={() => scrollLeft(gridTwoRef)}
                    >
                        <FontAwesomeIcon
                            className="carousel-button-icon"
                            icon={faChevronLeft}
                        />
                    </button>
                    <div className="combined-achievement-grid" ref={gridTwoRef}>
                        {props.achievementsTwo}
                    </div>
                    <button
                        className="carousel-button"
                        onClick={() => scrollRight(gridTwoRef)}
                    >
                        <FontAwesomeIcon
                            className="carousel-button-icon"
                            icon={faChevronRight}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CombinedAchievementContainer;
