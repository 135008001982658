import React, { useEffect, useState } from 'react';
import './Boost.scss';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import BoostModal from '../../modals/BoostModal/BoostModal';
import Storage from '../../assets/components/BoostIcon/Storage.svg';
import Mining from '../../assets/components/BoostIcon/Mining.svg';
import rigelWhiteIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import rigelGradientIcon from '../../assets/components/StarIcon/RigelGradient.svg';
import commingSoonIcon from '../../assets/components/CommingSoon/CommingSoonIcon.svg';
import boostHeaderInfo from '../../assets/components/BoostIcon/BoostHeaderIcon.png';
import storageOneIcon from '../../assets/components/BoostIcon/StorageOneIcon.svg';
import storageTwoIcon from '../../assets/components/BoostIcon/Storage.svg';
import miningOneIcon from '../../assets/components/BoostIcon/Mining.svg';
import miningTwoIcon from '../../assets/components/BoostIcon/MiningTwoIcon.svg';
import autocollectorIcon from '../../assets/components/AutoCollectorIcon/AutoCollectorIconTwo.svg';
import incomeIncreaseIcon from '../../assets/components/IncomeIncreaseIcon/IncomeIncreaseIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import BoostItem from '../../components/BoostItem/BoostItem';
import Popup from '../../components/Popup/Popup';
import BoostProductItem from '../../components/BoostProductItem/BoostProductItem';
import AutoCollectorUpgradeModal from '../../modals/AutoCollectorUpgradeModal/AutoCollectorUpgradeModal';
import IncomeIncreaseUpgradeModal from '../../modals/IncomeIncreaseUpgradeModal/IncomeIncreaseUpgradeModal';
import { motion } from 'framer-motion';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';

const coinname = process.env.REACT_APP_COIN_NAME;

const stubBoost = {
    id: 999,
    name: 'Income Increase',
    price: '0.200000',
    type: 'Locked',
    options: { tokens_per_hour: '0.15' },
    level: 1,
};

const autoCollectorBoost = {
    id: 1000,
    name: 'Auto-collector',
    level: 1,
};

const incomeIncreaseBoost = {
    id: 1001,
    name: 'Income increase',
    level: 1,
};

const Boost = () => {
    const userInfo = useSelector((state) => state.common.userInfo);
    const boostData = useSelector((state) => state.common.boosts);
    const totalFriends = useSelector((state) => state.common.totalFriends);
    const products = useSelector((state) => state.common.products);
    const autoCollectorItem = products.AutoClaim;
    const incomeIncreaseItem = products.RateMining;

    const pruchasedIncomeIncreaseItem = incomeIncreaseItem.levels.find(
        (x) => x.id === incomeIncreaseItem?.purchased?.id
    );

    const [boosts, setBoosts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
    const [selectedBoost, setSelectedBoost] = useState(null);

    const currentClaimHours = userInfo.options.claim_hours;
    const currentTokensPerHour = userInfo.options.tokens_per_hour;

    const [openCollectorModal, setOpenCollectorModal] = useState(false);
    const [openIncomeModal, setOpenIncomeModal] = useState(false);

    useEffect(() => {
        if (Array.isArray(boostData)) {
            let updatedBoosts = boostData.map((boost) => {
                if (boost.type === 'Storage') {
                    return {
                        ...boost,
                        imageSrc: Storage,
                        altText: 'Storage',
                    };
                } else if (boost.type === 'Tokens') {
                    return {
                        ...boost,
                        imageSrc: Mining,
                        altText: 'More Tokens',
                    };
                } else {
                    return {
                        ...boost,
                        imageSrc: '',
                        altText: '',
                    };
                }
            });

            const hasStorage = updatedBoosts.some(
                (boost) => boost.type === 'Storage'
            );
            const hasTokens = updatedBoosts.some(
                (boost) => boost.type === 'Tokens'
            );

            if (!hasStorage) {
                updatedBoosts.push({
                    id: 'custom-storage',
                    name: 'Storage',
                    price: 'N/A',
                    type: 'Storage',
                    options: {
                        claim_time: currentClaimHours,
                        required_referrals: 0,
                    },
                    level: 'Max Level',
                    imageSrc: Storage,
                    altText: 'Storage',
                    loading: false,
                    disabled: true,
                });
            }

            if (!hasTokens) {
                updatedBoosts.push({
                    id: 'custom-tokens',
                    name: 'Tokens',
                    price: 'N/A',
                    type: 'Tokens',
                    options: {
                        tokens_per_hour: currentTokensPerHour,
                        required_referrals: 0,
                    },
                    level: 'Max Level',
                    imageSrc: Mining,
                    altText: 'More Tokens',
                    loading: false,
                    disabled: true,
                });
            }

            setBoosts(updatedBoosts);
        }
    }, [boostData, currentClaimHours, currentTokensPerHour]);

    const handleModal = (boostIndex) => () => {
        const boostData = boosts[boostIndex];
        setSelectedBoost(boostData);
        setSelectedPurchaseId(boostData.id);
        setOpenModal(true);
    };

    const formatValue = (value) => {
        const number = parseFloat(value);
        if (isNaN(number)) return value;
        return Number.isInteger(number) ? number : number.toFixed(3);
    };

    const getUpgradeLevelText = (boost) => {
        if (boost.id === 'custom-storage' || boost.id === 'custom-tokens') {
            return `${boost.level}`;
        }
        return `Level ${boost.level}`;
    };

    const getUpgradeCost = (boost) => {
        if (boost.id === 'custom-storage' || boost.id === 'custom-tokens') {
            return null;
        }
        return Number(boost.price).toFixed(2);
    };

    const isAutoCollectorActive = autoCollectorItem.purchased ? true : false;
    const isIncomeIncreaseActive = incomeIncreaseItem.purchased ? true : false;

    return (
        <Wrapper>
            <motion.div
                className="boost-container"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <div className="boost-header-container">
                    <SuspenseImage
                        className="boost-icon"
                        src={boostHeaderInfo}
                        alt="user avatar"
                        width="8rem"
                        height="8rem"
                    ></SuspenseImage>
                    <div className="boost-data">
                        <span className="boost-name">Research Lab</span>
                        <div className="boost-info">
                            <span className="info">
                                Upgrade container for longer mining and increase
                                miner houses for more productivity
                            </span>
                        </div>
                    </div>
                </div>
                <div className="content-container">
                    <div className="boost-label-container">
                        <label className="label">Upgrade Stats</label>
                        <div className="boost-label-info">
                            <div className="boost-label">
                                <SuspenseImage
                                    className="boost-label-icon"
                                    src={rigelWhiteIcon}
                                    alt="user coins"
                                    width="1.4rem"
                                    height="1.4rem"
                                ></SuspenseImage>
                            </div>
                            <span>
                                {Number(userInfo.balance).toFixed(3) || (
                                    <Skeleton />
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="boost-items-scroller">
                        <div className="boost-items-wrapper">
                            {Array.isArray(boosts) &&
                                boosts.map((boost, index) => (
                                    <BoostItem
                                        id={boost.id}
                                        key={index}
                                        imageSrc={boost.imageSrc}
                                        altText={boost.altText}
                                        title={boost.name}
                                        claimTime={
                                            boost.options.claim_time
                                                ? `Claim every ${userInfo.options.claim_hours} hours`
                                                : `${Number(
                                                      userInfo.options
                                                          .tokens_per_hour
                                                  ).toFixed(2)} / hour`
                                        }
                                        currentReferrals={Number(totalFriends)}
                                        requiredReferrals={Number(
                                            boost.options.required_referrals
                                        )}
                                        cost={getUpgradeCost(boost)}
                                        upgradeData={
                                            boost.options.claim_time
                                                ? `${boost.options.claim_time} HOURS`
                                                : `${
                                                      boost.options
                                                          .tokens_per_hour
                                                  } ${coinname.toUpperCase()}`
                                        }
                                        upgradeAdditionalInfo={boost.name.toUpperCase()}
                                        upgradeAdditionalLevel={getUpgradeLevelText(
                                            boost
                                        )}
                                        handleModal={
                                            boost.disabled ||
                                            Number(
                                                boost.options.required_referrals
                                            ) > Number(totalFriends)
                                                ? () => {}
                                                : () => handleModal(index)
                                        }
                                        classname={
                                            boost.disabled ? 'disabled' : ''
                                        }
                                        loading={boost.loading}
                                        disabled={boost.disabled}
                                    />
                                ))}
                            <BoostProductItem
                                id={autoCollectorBoost.id}
                                key={autoCollectorBoost.id}
                                imageSrc={autocollectorIcon}
                                altText={''}
                                title={autoCollectorBoost.name}
                                data={`${
                                    isAutoCollectorActive
                                        ? 'Active'
                                        : 'Automatic Claims'
                                }`}
                                handleModal={setOpenCollectorModal}
                                validUntil={
                                    autoCollectorItem.purchased
                                        ? autoCollectorItem.purchased
                                              .finished_at
                                        : null
                                }
                                active={isAutoCollectorActive}
                            />
                            <BoostProductItem
                                id={incomeIncreaseBoost.id}
                                key={incomeIncreaseBoost.id}
                                imageSrc={incomeIncreaseIcon}
                                altText={''}
                                title={incomeIncreaseBoost.name}
                                data={`${
                                    isIncomeIncreaseActive
                                        ? 'Active'
                                        : 'Mining Speed Boost'
                                }`}
                                handleModal={setOpenIncomeModal}
                                validUntil={
                                    incomeIncreaseItem.purchased
                                        ? incomeIncreaseItem.purchased
                                              .finished_at
                                        : null
                                }
                                active={isIncomeIncreaseActive}
                                boostRate={
                                    pruchasedIncomeIncreaseItem
                                        ? Number(
                                              pruchasedIncomeIncreaseItem
                                                  .options.rate
                                          )
                                        : null
                                }
                            />
                            <BoostItem
                                id={stubBoost.id}
                                key={999}
                                imageSrc={Mining}
                                altText={''}
                                title={stubBoost.name}
                                currentReferrals={1}
                                requiredReferrals={0}
                                cost={9.9}
                                upgradeData={`0.01 HOURS`}
                                upgradeAdditionalInfo={stubBoost.name.toUpperCase()}
                                upgradeAdditionalLevel={`Level 99`}
                                handleModal={() => {}}
                                commingSoon={commingSoonIcon}
                                classname={'comming-soon-change'}
                                disabled={true}
                                loading={false}
                            />
                        </div>
                    </div>
                </div>
                <Popup
                    title="Upgrade"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                >
                    {selectedBoost && selectedPurchaseId && (
                        <BoostModal
                            id={selectedPurchaseId}
                            title={
                                selectedBoost.type === 'Storage'
                                    ? 'With a higher storage level, your capacity increases, meaning fewer trips to collect your rewards.'
                                    : 'Upgrading your mining level increases your coin production rate, giving you more rewards in less time.'
                            }
                            before={
                                selectedBoost.type === 'Storage' ? (
                                    `Claim every ${currentClaimHours} hours`
                                ) : (
                                    <>
                                        <img
                                            src={rigelWhiteIcon}
                                            alt="rigel white icon"
                                        ></img>
                                        {formatValue(currentTokensPerHour)} /
                                        hour
                                    </>
                                )
                            }
                            beforeIcon={
                                selectedBoost.type === 'Storage'
                                    ? `${storageOneIcon}`
                                    : `${miningOneIcon}`
                            }
                            after={
                                selectedBoost.type === 'Storage' ? (
                                    `Claim every ${
                                        selectedBoost?.options?.claim_time ?? 0
                                    } hours`
                                ) : (
                                    <>
                                        <img
                                            src={rigelGradientIcon}
                                            alt="rigel white icon"
                                        ></img>
                                        {formatValue(
                                            selectedBoost?.options
                                                ?.tokens_per_hour ?? 0
                                        )}{' '}
                                        / hour
                                    </>
                                )
                            }
                            afterIcon={
                                selectedBoost.type === 'Storage'
                                    ? `${storageTwoIcon}`
                                    : `${miningTwoIcon}`
                            }
                            underline={
                                selectedBoost.type === 'Storage'
                                    ? 'Hours of farm'
                                    : 'Tokens per hour'
                            }
                            userInfo={userInfo}
                            cost={selectedBoost?.price}
                            onClose={() => setOpenModal(false)}
                            boostLevel={selectedBoost?.level}
                            boosts={boosts}
                        />
                    )}
                </Popup>
                <Popup
                    title="Auto-Collector"
                    className="payment-popup"
                    open={openCollectorModal}
                    onClose={() => setOpenCollectorModal(false)}
                >
                    <AutoCollectorUpgradeModal
                        onClose={() => setOpenCollectorModal(false)}
                    />
                </Popup>
                <Popup
                    title="Mining Speed Boost"
                    className="payment-popup"
                    open={openIncomeModal}
                    onClose={() => {
                        setOpenIncomeModal(false);
                    }}
                >
                    <IncomeIncreaseUpgradeModal
                        onClose={() => {
                            setOpenIncomeModal(false);
                        }}
                    />
                </Popup>
            </motion.div>
        </Wrapper>
    );
};

export default Boost;
