import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './SuspenseImage.scss';

const SuspenseImage = ({
    src,
    alt,
    width,
    height,
    cricle,
    className,
    containerClassName,
    customWrapperStyle,
}) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div style={{ display: 'flex', ...customWrapperStyle }}>
            {!loaded && (
                <Skeleton
                    className={className}
                    width={width}
                    height={height}
                    containerClassName={`skeleton-container ${
                        containerClassName ? containerClassName : ''
                    }`}
                    circle={cricle}
                />
            )}
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                onLoad={() => setLoaded(true)}
                className={className}
                style={{
                    display: loaded ? 'block' : 'none',
                    width: width,
                    height: height,
                }}
            />
        </div>
    );
};

export default SuspenseImage;
