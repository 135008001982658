import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './Button.scss';

const Button = (props) => {
    const history = useHistory();
    const location = useLocation();
    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    };

    const isActive = props.to && location.pathname === props.to;

    const icon = isActive && props.activeIcon ? props.activeIcon : props.icon;

    if (props.back) {
        return (
            <button
                onClick={goBack}
                className={`link${props.type ? props.type : ''} ${
                    props.className ? props.className : ''
                } ${props.disabled ? 'disabled' : ''}`}
                style={props.style}
                disabled={props.disabled}
            >
                {icon && <div className="inner-icon">{icon}</div>}
                {props.children && (
                    <div
                        className={`${
                            props.undertext ? 'under-text' : 'inner-children'
                        }`}
                    >
                        {props.children}
                    </div>
                )}
            </button>
        );
    } else if (props.to) {
        return (
            <Link
                to={props.to}
                className={`link${props.type ? props.type : ''} ${
                    props.className ? props.className : ''
                } ${props.disabled ? 'disabled' : ''} ${
                    isActive ? 'active' : ''
                }`}
                style={props.style}
                disabled={props.disabled}
            >
                {icon && <div className="inner-icon">{icon}</div>}
                {props.children && (
                    <div
                        className={`${
                            props.undertext ? 'under-text' : 'inner-children'
                        }`}
                    >
                        {props.children}
                    </div>
                )}
                {props.notification && (
                    props.notification
                )}
            </Link>
        );
    } else if (props.onClick) {
        return (
            <button
                className={`button${props.type ? props.type : ''} ${
                    props.className ? props.className : ''
                } ${props.disabled ? 'disabled' : ''}`}
                style={props.style}
                disabled={props.disabled}
                onClick={props.onClick}
            >
                {icon && <div className="inner-icon">{icon}</div>}
                {props.children && (
                    <div
                        className={`${
                            props.undertext ? 'under-text' : 'inner-children'
                        }`}
                    >
                        {props.children}
                    </div>
                )}
            </button>
        );
    } else {
        return (
            <button
                className={`button${props.type ? props.type : ''} ${
                    props.className ? props.className : ''
                } ${props.disabled ? 'disabled' : ''}`}
                style={props.style}
                disabled={props.disabled}
            >
                {icon && <div className="inner-icon">{icon}</div>}
                {props.children && (
                    <div
                        className={`${
                            props.undertext ? 'under-text' : 'inner-children'
                        }`}
                    >
                        {props.children}
                    </div>
                )}
            </button>
        );
    }
};

export default Button;
