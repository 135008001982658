import { useIsConnectionRestored, useTonAddress } from '@tonconnect/ui-react';
import './TonAddressInfo.scss';
import { useEffect, useState } from 'react';
import TonDisconnect from '../TonDisconnect/TonDisconnect';
import walletIcon from '../../assets/components/WalletIcon/WalletIcon.svg';

const TonAddressInfo = () => {
    const address = useTonAddress();
    const isConnectionRestored = useIsConnectionRestored();

    const getTxButtonContent = () => {
        if (!isConnectionRestored) return 'Loading...';
        return address ? `${address}` : `Connect TON`;
    };
    useEffect(() => {
        setBtnContent(getTxButtonContent());
    }, [isConnectionRestored, address]);

    const [btnContent, setBtnContent] = useState(getTxButtonContent());

    return (
        <div className="ton-address-info">
            <div className="ton-container">
                <img src={walletIcon} alt="wallet icon"></img>
                <span className="ton-content">{btnContent}</span>
            </div>
            {address && <TonDisconnect />}
        </div>
    );
};

export default TonAddressInfo;
