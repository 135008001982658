import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import NoImgIcon from '../../assets/components/NoImgIcon/NoImgIcon.png';
import toast from 'react-hot-toast';
import './Achievement.scss';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';

const Achievement = (props) => {
    const [isClaimed, setIsClaimed] = useState(false);
    const [isCanBeCompleted, setIsCanBeCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleIsClaimed = async () => {
        if (isClaimed) {
            toast("You've already claimed this achievement");
        } else if (!isClaimed && isCanBeCompleted) {
            setIsLoading(true);
            try {
                const result = await props.handleCompleteAchievement(props.id);
                if (result) {
                    setIsClaimed(true);
                }
            } catch (error) {
                console.error('Error completing achievement:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            toast('Complete tasks to claim this achievement');
        }
    };

    useEffect(() => {
        setIsClaimed(props.completed);
    }, [props.completed]);

    useEffect(() => {
        setIsCanBeCompleted(props.canBeCompleted);
    }, [props.canBeCompleted]);

    return (
        <Button
            className={`achievement-card ${
                isClaimed ? 'achievement-claimed' : ''
            }`}
            onClick={handleIsClaimed}
        >
            <div className="achievement-element">
                <div className="element-icon">
                    <SuspenseImage
                        className="icon"
                        src={props.icon || NoImgIcon}
                        alt="achievement icon"
                        width="6rem"
                        height="6rem"
                        cricle={true}
                    ></SuspenseImage>
                </div>
                <div className="element-reward">
                    <span className="reward">
                        {(props.reward
                            ? Number(props.reward).toFixed(3)
                            : props.reward) || <Skeleton />}
                    </span>
                </div>
            </div>
            <div className="element-description">
                {!isClaimed && (
                    <span className="description">
                        {props.description || <Skeleton />}
                    </span>
                )}
                {isClaimed && (
                    <div className="claimed-wrapper">
                        <FontAwesomeIcon className="icon" icon={faCheck} />
                        <span className="text">Done</span>
                    </div>
                )}
            </div>

            {isLoading && (
                <div className="loader-mask">
                    <span className="loader"></span>
                </div>
            )}
        </Button>
    );
};

export default Achievement;
