import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import GuildInfo from '../../components/GuildInfo/GuildInfo';
import CoinInfo from '../../components/CoinInfo/CoinInfo';
import Storage from '../../components/Storage/Storage';
import './Main.scss';
import Popup from '../../components/Popup/Popup';
import Modal from '../../components/Modal/Modal';
import AutoCollectoUpgradeModal from '../../modals/AutoCollectorUpgradeModal/AutoCollectorUpgradeModal';
import X2ClaimModal from '../../modals/X2ClaimModal/X2ClaimModal';
import X2PaymentClaimModal from '../../modals/X2PaymentClaimModal/X2PaymentClaimModal';
import IncomeIncreaseUpgradeModal from '../../modals/IncomeIncreaseUpgradeModal/IncomeIncreaseUpgradeModal';
import useMinedCoins from '../../hooks/useMinedCoins';
import useIncomeBoostMinedCoins from '../../hooks/useIncomeBoostMinedCoins';
/*import DailyClaimModal from '../../modals/DailyClaimModal/DailyClaimModal';*/

import { motion } from 'framer-motion';

const Main = () => {
    const userInfo = useSelector((state) => state.common.userInfo);
    const products = useSelector((state) => state.common.products);
    const incomeIncreaseItem = products.RateMining;

    const [openCollectorModal, setOpenCollectorModal] = useState(false);
    const [openIncomeModal, setOpenIncomeModal] = useState(false);
    const [openX2Modal, setOpenX2Modal] = useState(false);
    const [openClaimPaymentModal, setOpenClaimPaymentModal] = useState(false);
    /*const [openDailyClaimModal, setOpenDailyClaimModal] = useState(true);*/

    const [tonAmountForPayment, setTonAmountForPayment] = useState(0);
    const [starsAmountForPayment, setStarsAmountForPayment] = useState(0);
    const [dollarAmountForPayment, setDollarAmountForPayment] = useState(0);

    const isIncomeIncreaseActive = incomeIncreaseItem.purchased ? true : false;

    const coinsMined = useMinedCoins(
        userInfo?.claim?.created_at,
        userInfo?.claim?.finished_at,
        userInfo?.options?.tokens_per_hour,
        userInfo?.options?.claim_hours
    );

    const boostCoinsMined = useIncomeBoostMinedCoins(
        userInfo?.claim?.tokens_per_sec,
        userInfo?.claim?.boost_claimed,
        userInfo?.rate_mining?.options?.rate,
        userInfo?.rate_mining?.finished_at,
        userInfo?.rate_mining?.started_at,
        userInfo?.claim?.created_at,
        userInfo?.claim?.finished_at
    );

    return (
        <Wrapper>
            <motion.div
                className="main-page"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <GuildInfo />
                <div className="coin-storage">
                    <CoinInfo
                        balance={userInfo?.balance}
                        boostRate={userInfo?.rate_mining?.options?.rate}
                        boostFinishedAt={userInfo?.rate_mining?.finished_at}
                        boostRateMiningStatus={userInfo?.rate_mining?.status}
                        coinsMined={coinsMined}
                        boostCoinsMined={boostCoinsMined}
                    >
                        <Storage
                            tokensPerHour={userInfo.options.tokens_per_hour}
                            claim={userInfo.claim}
                            balance={userInfo.balance}
                            coinsMined={coinsMined}
                            boostCoinsMined={boostCoinsMined}
                            setOpenCollectorModal={setOpenCollectorModal}
                            setOpenIncomeModal={setOpenIncomeModal}
                            setOpenX2Modal={setOpenX2Modal}
                        />
                    </CoinInfo>
                </div>
            </motion.div>
            <Popup
                title="Auto-Collector"
                className="payment-popup"
                open={openCollectorModal}
                onClose={() => {
                    setOpenCollectorModal(false);
                }}
            >
                <AutoCollectoUpgradeModal
                    onClose={() => {
                        setOpenCollectorModal(false);
                    }}
                />
            </Popup>
            <Popup
                title="Mining Speed Boost"
                className="payment-popup"
                open={openIncomeModal}
                onClose={() => {
                    setOpenIncomeModal(false);
                }}
            >
                <IncomeIncreaseUpgradeModal
                    onClose={() => {
                        setOpenIncomeModal(false);
                    }}
                />
            </Popup>
            {/*<Modal
                className="glowing-modal"
                title="Your daily rewards"
                open={openDailyClaimModal}
                onClose={() => setOpenDailyClaimModal(false)}
                closeBtnDisabled={true}
            >
                <DailyClaimModal
                    onClose={() => setOpenDailyClaimModal(false)}
                    setOpenClaimPaymentModal={() =>
                        setOpenDailyClaimModal(true)
                    }
                    title="You’ve been randomly selected for a double reward"
                    createdAt={userInfo.claim.created_at}
                    balance={userInfo.balance}
                />
            </Modal>*/}
            <Modal
                className="glowing-modal"
                title="Lucky You!"
                open={openX2Modal}
                onClose={() => setOpenX2Modal(false)}
                setOpenClaimPaymentModal={() => setOpenClaimPaymentModal(true)}
                closeBtnDisabled={true}
            >
                <X2ClaimModal
                    title="You’ve been randomly selected for a double reward"
                    onClose={() => setOpenX2Modal(false)}
                    setOpenClaimPaymentModal={() =>
                        setOpenClaimPaymentModal(true)
                    }
                    setTonAmountForPayment={setTonAmountForPayment}
                    setStarsAmountForPayment={setStarsAmountForPayment}
                    setDollarAmountForPayment={setDollarAmountForPayment}
                    coinsMined={coinsMined}
                    boostCoinsMined={boostCoinsMined}
                />
            </Modal>
            <Popup
                title="Collect x2 Reward"
                className="payment-popup"
                open={openClaimPaymentModal}
                onClose={() => setOpenClaimPaymentModal(false)}
            >
                <X2PaymentClaimModal
                    onClose={() => setOpenClaimPaymentModal(false)}
                    starsAmount={starsAmountForPayment}
                    tonAmount={tonAmountForPayment}
                    dollarAmount={dollarAmountForPayment}
                    userInfo={userInfo}
                />
            </Popup>
        </Wrapper>
    );
};

export default Main;
