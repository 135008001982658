import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from './routes/index';
import NotFound from './pages/NotFound/NotFound';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import Navbar from './layouts/Navbar/Navbar';
import Main from './pages/Main/Main';
import Missions from './pages/Missions/Missions';
import Boost from './pages/Boost/Boost';
import Friends from './pages/Friends/Friends';
import { trackPageView } from './analytics';

const SentryRoute = Sentry.withSentryRouting(Route);

const Apps = lazy(() => import('./pages/Apps/Apps'));
const GuildBoost = lazy(() => import('./pages/GuildBoost/GuildBoost'));
const GuildList = lazy(() => import('./pages/GuildList/GuildList'));
const Guild = lazy(() => import('./pages/Guild/Guild'));

export default function RouterSwitch() {
    const location = useLocation();

    useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);

    const navbarPaths = [
        ROUTES.MAIN,
        ROUTES.MISSIONS,
        ROUTES.BOOST,
        ROUTES.FRIENDS,
    ];

    const showNavbar = navbarPaths.includes(location.pathname);

    return (
        <>
            <AnimatePresence>
                <Switch>
                    <SentryRoute exact path={ROUTES.MAIN}>
                        <Main />
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.MISSIONS}>
                        <Missions />
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.BOOST}>
                        <Boost />
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.FRIENDS}>
                        <Friends />
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.APPS}>
                        <Suspense fallback={null}>
                            <Apps />
                        </Suspense>
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.GUILD}>
                        <Suspense fallback={null}>
                            <Guild />
                        </Suspense>
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.GUILD_BOOST}>
                        <Suspense fallback={null}>
                            <GuildBoost />
                        </Suspense>
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.GUILD_LIST}>
                        <Suspense fallback={null}>
                            <GuildList />
                        </Suspense>
                    </SentryRoute>
                    <SentryRoute exact path={ROUTES.NOT_FOUND}>
                        <NotFound />
                    </SentryRoute>
                </Switch>
            </AnimatePresence>
            {showNavbar && (
                <div
                    className="router-footer"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 1rem 0',
                        height: '100%',
                        justifyContent: 'flex-end',
                        bottom: '0',
                        marginBottom: 'env(safe-area-inset-bottom)',
                        pointerEvents: 'none',
                    }}
                >
                    <Navbar />
                </div>
            )}
        </>
    );
}
