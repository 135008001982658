import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ progress, balance }) => {
    const width = balance === '0.000000' && progress === null ? 100 : progress;

    return (
        <div className="progress-bar">
            <div className="success-line" style={{ width: `${width}%` }}></div>
        </div>
    );
};

export default ProgressBar;
