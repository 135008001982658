import React, { useEffect, useState } from 'react';

const useMinedTokens = (createdAt, finishedAt, tokensPerHour, claimHours) => {
    const [minedTokens, setMinedTokens] = useState(0);

    useEffect(() => {
        const calculateTokens = () => {
            const createdAtDate = new Date(createdAt).getTime();
            const finishedAtDate = new Date(finishedAt).getTime();
            const now = Date.now();

            if (isNaN(createdAtDate) || isNaN(finishedAtDate)) {
                console.error('Invalid dates for createdAt or finishedAt');
                return;
            }

            const tokensPerSecond = parseFloat(tokensPerHour) / 3600;
            if (isNaN(tokensPerSecond)) {
                console.error('Invalid tokensPerHour value', tokensPerHour);
                return;
            }

            // Calculate elapsed time
            const maxClaimSeconds = claimHours * 3600;
            let elapsedTime = Math.max((now - createdAtDate) / 1000, 0);
            elapsedTime = Math.min(
                elapsedTime,
                maxClaimSeconds,
                (finishedAtDate - createdAtDate) / 1000
            );

            const currentMinedTokens = elapsedTime * tokensPerSecond;

            setMinedTokens(currentMinedTokens.toFixed(6));
        };

        calculateTokens();
        const interval = setInterval(calculateTokens, 1000);

        return () => clearInterval(interval);
    }, [createdAt, finishedAt, tokensPerHour, claimHours]);

    return minedTokens;
};

export default useMinedTokens;
