import { useState, useEffect } from 'react';

const useIncomeBoostMinedCoins = (
    tokensPerSec, // Tokens per second from common mining system
    boostClaimed,
    boostRate,
    boostFinishedAt,
    boostStartedAt,
    createdAt,
    finishedAt // Common mining system finish time
) => {
    const [boostMinedTokens, setBoostMinedTokens] = useState(0);

    const parsedBoostClaimed = parseFloat(boostClaimed);
    const parsedBoostRate = Number(boostRate);

    useEffect(() => {
        const calculateBoostMinedTokens = () => {
            const now = Date.now();
            const boostStartTime = new Date(boostStartedAt).getTime();
            const boostEndTime = new Date(boostFinishedAt).getTime();
            const commonMiningStartTime = new Date(createdAt).getTime();
            const commonMiningEndTime = new Date(finishedAt).getTime(); // End time for common mining

            // Check if the common mining finished before now
            const effectiveEndTime = Math.min(
                boostEndTime,
                commonMiningEndTime
            );

            const effectiveStartTime =
                commonMiningStartTime > boostStartTime &&
                commonMiningStartTime < effectiveEndTime
                    ? commonMiningStartTime
                    : boostStartTime;

            if (now >= effectiveStartTime && now <= effectiveEndTime) {
                // Calculate the time passed since the boost started, in seconds
                const timePassedInSeconds = (now - effectiveStartTime) / 1000;
                // Calculate the base mined tokens from the common mining system
                const baseMinedTokens = timePassedInSeconds * tokensPerSec;
                // Apply the boost rate and subtract the base tokens to get the boosted tokens
                let totalBoostedTokens =
                    baseMinedTokens * parsedBoostRate - baseMinedTokens;

                if (totalBoostedTokens < parsedBoostClaimed) {
                    totalBoostedTokens = parsedBoostClaimed;
                }

                setBoostMinedTokens(totalBoostedTokens.toFixed(6));
            } else {
                if (parsedBoostClaimed > 0) {
                    setBoostMinedTokens(parsedBoostClaimed.toFixed(6));
                } else {
                    setBoostMinedTokens(0);
                }
            }
        };

        calculateBoostMinedTokens();
        const interval = setInterval(calculateBoostMinedTokens, 1000); // Recalculate every second

        return () => clearInterval(interval);
    }, [
        tokensPerSec,
        parsedBoostClaimed,
        parsedBoostRate,
        boostFinishedAt,
        boostStartedAt,
        createdAt,
        finishedAt,
    ]);

    return boostMinedTokens;
};

export default useIncomeBoostMinedCoins;
