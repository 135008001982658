import React from 'react';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import guildIcon from '../../assets/components/GuildIcon/GuildIcon.png';
import rubyIcon from '../../assets/components/RubyIcon/RubyCoin.png';
import Button from '../Button/Button';
import './GuildInfo.scss';

const GuildInfo = (props) => {
    return (
        <div className="guild-info">
            <Button className="guild-info-container" to="/guild">
                <div
                    className="main-container"
                    //style={{ filter: 'grayscale(100%)' }}
                >
                    <div className="guild-left-container">
                        <div className="guild-img">
                            <SuspenseImage
                                src={guildIcon}
                                alt="guild icon"
                                width="4rem"
                                height="4rem"
                                cricle="true"
                            />
                        </div>
                        <div className="info-container">
                            <span className="title">
                                {props.guild_title || 'Join the guild!' || (
                                    <Skeleton width="100%" />
                                )}
                            </span>
                            {props.counter && (
                                <div className="count">
                                    <SuspenseImage
                                        src={rubyIcon}
                                        alt="diamond icon"
                                        width="2rem"
                                        height="2rem"
                                        cricle="true"
                                    />
                                    {props.counter.toFixed(4) || (
                                        <Skeleton
                                            width="100%"
                                            containerClassName="skeleton-fix flex-1"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="forward-icon-container">
                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </div>
                </div>
            </Button>
        </div>
    );
};

export default GuildInfo;
