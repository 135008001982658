import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import commonReducer from './commonReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

export const store = configureStore(
    {
        reducer: { common: commonReducer },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                    ignoredPaths: ['common.socket'],
                },
            }),
        enhancers: (getDefaultEnhancers) => {
            return getDefaultEnhancers().concat(sentryReduxEnhancer);
        },
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
