import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import autoCollectorIcon from '../../assets/components/AutoCollectorIcon/AutoCollectorIcon.svg';
import cautionIcon from '../../assets/components/Caution/CautionIcon.svg';
import toncoinIcon from '../../assets/components/TonIcon/TonIcon.svg';
import bestDealIcon from '../../assets/components/BestDealIcon/BestDealIcon.svg';
import './AutoCollectorUpgradeModal.scss';
import { useTonAddress } from '@tonconnect/ui-react';
import TonAddressInfo from '../../components/TonAddressInfo/TonAddressInfo';
import TonSendTxBoost from '../../components/TonSendTransaction/TonSendTxBoost';
import { useDispatch, useSelector } from 'react-redux';
import { convertDollarToTon } from '../../tools/common';
import { buyProductWithTgStars, getTonInfo } from '../../tools/requests';
import toast from 'react-hot-toast';

const AutoCollectorUpgradeModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const address = useTonAddress();

    const tg = window.Telegram.WebApp;
    const initData = tg.initData;

    const userInfo = useSelector((state) => state.common.userInfo);
    const tonRate = useSelector((state) => state.common.tonAmount);
    const products = useSelector((state) => state.common.products);
    let autoClaimProducts = products.AutoClaim.levels;

    autoClaimProducts = autoClaimProducts.map((product, index) => ({
        ...product,
        icon: index === autoClaimProducts.length - 1 ? bestDealIcon : null,
    }));

    const [selected, setSelected] = useState(false);
    const [selectedBoost, setSelectedBoost] = useState(
        autoClaimProducts[autoClaimProducts.length - 1]?.id || null
    );

    const [tonAmount, setTonAmount] = useState(0);
    const [starsAmount, setStarsAmount] = useState(0);
    const [dollarAmount, setDollarAmount] = useState(0);
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

    const isLastBoost =
        selectedBoost === autoClaimProducts[autoClaimProducts.length - 1].id;

    const handleStarsPayment = async () => {
        if (isInvoiceOpen) {
            toast('Only one payment can be processed at the same time.');
            return;
        }

        setIsInvoiceOpen(true);
        const result = await buyProductWithTgStars(
            userInfo.chat_id,
            selectedBoost,
            initData,
            starsAmount
        );
        if (result && result.url) {
            tg.openInvoice(result.url, (invoiceStatus) => {
                setIsInvoiceOpen(false);
                if (invoiceStatus === 'paid') {
                    onClose();
                }
            });
        } else {
            console.error('Invoice URL is invalid', result);
            setIsInvoiceOpen(false);
        }
    };

    useEffect(() => {
        if (selectedBoost !== null) {
            const product = autoClaimProducts.find(
                (item) => item.id === selectedBoost
            );
            if (product) {
                const dollarPrice = product.price.USD;
                const starsPrice = product.price.XTR;
                const tonPrice = convertDollarToTon(dollarPrice, tonRate);

                setDollarAmount(dollarPrice);
                setTonAmount(tonPrice);
                setStarsAmount(starsPrice);
            }
        }
    }, [selectedBoost, autoClaimProducts, tonRate]);

    const handleBoostClick = (boostId) => {
        setSelectedBoost(boostId);
    };

    useEffect(() => {
        getTonInfo(initData, dispatch);
    }, [initData, dispatch]);

    return (
        <div className="auto-collector-container">
            {!selected ? (
                <>
                    <span className="auto-collector-description">
                        This smart bot automatically collects your rewards when
                        your storage is full, so you don't need to log into the
                        app every time.
                    </span>
                    <div className="auto-collector-option-label">
                        Available Options
                    </div>
                    <div className="auto-collector-three-boosts">
                        {autoClaimProducts.map((boost) => (
                            <div
                                className={`ac-boost-container ${
                                    selectedBoost === boost.id
                                        ? 'background-active'
                                        : ''
                                }`}
                                key={boost.id}
                            >
                                <span className="level">
                                    Level {boost.level}
                                </span>
                                <span className="timeframe">
                                    {boost.options.duration === '2000 days'
                                        ? 'Unlimited'
                                        : `${boost.options.duration}`}
                                </span>
                                <span className="ton-img">
                                    {`$ ${boost.price.USD}`}
                                </span>
                                <Button
                                    className={`select ${
                                        selectedBoost === boost.id
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => handleBoostClick(boost.id)}
                                >
                                    Select
                                </Button>
                                {boost.icon && (
                                    <img
                                        className="best-deal-icon"
                                        src={boost.icon}
                                        alt="best deal icon"
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="auto-collector-discription-boost">
                        <div className="ac-boost-container">
                            <div className="boost-left-part">
                                <div className="boost-img-container">
                                    <img
                                        src={cautionIcon}
                                        alt="auto collector icon"
                                    />
                                </div>
                                <div className="boost-info">
                                    <span className="boost-title">
                                        Auto-Collector storage is limited to 3
                                        claims!
                                    </span>
                                    <span className="boost-description">
                                        Once those claims are used, you’ll need
                                        to re-enter the app to continue using
                                        the bot.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-collector-upgrade-cost">
                        <span className="upgrade-cost-label">Upgrade Cost</span>
                        {selectedBoost !== null && (
                            <span className="upgrade-cost">
                                {`$ ${dollarAmount}`}
                            </span>
                        )}
                    </div>
                    <div className="auto-collector-upgrade-button">
                        <Button
                            onClick={() => setSelected(true)}
                            className="select glowing"
                        >
                            Buy Upgrade
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <span className="auto-collector-description">
                        This smart bot automatically collects your rewards when
                        your storage is full, so you don't need to log into the
                        app every time.
                    </span>
                    <span className="confirm-buy-label">Chosen Option</span>
                    <div
                        className={`confirm-ac-boost-container background-active`}
                    >
                        <div className="choosen-boost-info">
                            <div className="boost-img-container">
                                <img
                                    src={autoCollectorIcon}
                                    alt="auto collector icon"
                                />
                            </div>
                            <div className="boost-info">
                                <span className="text-and-level">
                                    <span>Level</span>
                                    <span>
                                        {
                                            autoClaimProducts.find(
                                                (item) =>
                                                    item.id === selectedBoost
                                            ).level
                                        }
                                    </span>
                                </span>
                                <span>
                                    {
                                        autoClaimProducts.find(
                                            (item) => item.id === selectedBoost
                                        ).options.duration
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="boost-right-part">
                            <div className="ton-cost">
                                <img src={toncoinIcon} alt="ton coin icon" />
                                <span>{tonAmount}</span>
                            </div>
                        </div>
                        {isLastBoost && (
                            <img
                                className="best-deal-icon"
                                src={bestDealIcon}
                                alt="best deal icon"
                            />
                        )}
                    </div>
                    <div className="confirm-collector-container">
                        <div className="modal-container">
                            {address && (
                                <div className="ton-info">
                                    <TonAddressInfo />
                                </div>
                            )}
                            <div className="payment-info">
                                <div className="info-value">
                                    <span className="before-payment">
                                        {dollarAmount} $ = {tonAmount} TON
                                    </span>
                                </div>
                            </div>
                            <TonSendTxBoost
                                currentBoostId={selectedBoost}
                                tonAmount={tonAmount}
                                className="payment-btn glowing"
                                onClose={onClose}
                                userId={userInfo.chat_id}
                                itemType={'product'}
                                itemId={selectedBoost}
                            />
                            <Button
                                className="payment-btn glowing"
                                onClick={handleStarsPayment}
                            >
                                Pay {starsAmount} Stars
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AutoCollectorUpgradeModal;
