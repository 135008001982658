import React, { useEffect, useState } from 'react';
import closeIcon from '../../assets/components/CloseIcon/Close.svg';
import './Popup.scss';

const Popup = ({
    className,
    title,
    open,
    onClose,
    footer,
    footerStyle,
    children,
    closeBtnDisabled,
}) => {
    const [modalOpen, setModalOpen] = useState(open);
    const [closing, setClosing] = useState(false);

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    const handleClose = () => {
        setClosing(true);
        setTimeout(() => {
            setModalOpen(false);
            onClose();
            setClosing(false);
        }, 300);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    if (!modalOpen) return null;
    return (
        <div
            className="popup-overlay"
            onClick={closeBtnDisabled ? null : handleOverlayClick}
        >
            <div className={`popup ${className} ${closing ? 'close' : ''}`}>
                <div className="popup-header">
                    <div className="popup-title">{title}</div>
                    {!closeBtnDisabled && (
                        <img
                            className="close-button"
                            onClick={handleClose}
                            src={closeIcon}
                            alt=""
                        ></img>
                    )}
                </div>
                <div className="popup-content">{children}</div>
                {footer && (
                    <div className="popup-footer" style={footerStyle}>
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup;
