import log from 'loglevel';
import toast from 'react-hot-toast';
import {
    setIsPaymentLoading,
    setOpenResumeCollectorModal,
} from '../store/commonReducer';
import {
    getProducts,
    getUserAchievements,
    getUserTasks,
    sendUserInfo,
} from './requests';

const getTokenData = (token) =>
    token ? JSON.parse(window.atob(token.split('.')[1])) : false;

const formatBytes = (bytes) => {
    if (bytes < 1024) {
        return bytes + ' bytes';
    } else if (bytes < 1048576) {
        return (bytes / 1024).toFixed(2) + ' kb';
    } else {
        return (bytes / 1048576).toFixed(2) + ' mb';
    }
};

const htmlDecode = (str) => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return doc.documentElement.textContent;
};

const decodeQueryString = (queryString) => {
    const decoded = queryString.split('&').reduce((acc, part) => {
        const [key, value] = part.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
    }, {});

    // Parse inner attributes with JSON strings into objects
    Object.keys(decoded).forEach((key) => {
        try {
            decoded[key] = JSON.parse(decoded[key]);
        } catch (e) {
            // Not a JSON string, leave as is
        }
    });

    return decoded;
};

const writeFile = async (data) => {
    try {
        const handle = await window.showSaveFilePicker({
            suggestedName: 'initData.txt',
            types: [
                {
                    description: 'Text Files',
                    accept: {
                        'text/plain': ['.txt'],
                    },
                },
            ],
        });

        const writable = await handle.createWritable();
        await writable.write(data);
        await writable.close();
    } catch (error) {
        log.error('Error!', error);
    }
};

const displayNotification = (notification, initData, dispatch) => {
    const { title, status, type } = notification;

    if (type) {
        switch (type) {
            case 'init':
                sendUserInfo(initData, null, dispatch);
                break;
            case 'missions':
                getUserTasks(initData, dispatch);
                getUserAchievements(initData, dispatch);
                break;
            case 'auto_claim_resume':
                dispatch(setOpenResumeCollectorModal(true));
                sendUserInfo(initData, null, dispatch);
                break;
            case 'products':
                getProducts(initData, dispatch);
                sendUserInfo(initData, null, dispatch);
                dispatch(setIsPaymentLoading(false));
                break;
            default:
                break;
        }
    }

    if (status) {
        if (title) {
            switch (status) {
                case 'danger':
                    toast.error(title);
                    break;
                case 'info':
                    toast(title);
                    break;
                case 'success':
                    toast.success(title);
                    break;
                case 'warning':
                    toast.warn(title);
                    break;
                default:
                    toast(title);
                    break;
            }
        }
    }
};

const convertDollarToTon = (dollarAmount, tonRate) => {
    const tonValue =
        Number(dollarAmount).toFixed(6) / Number(tonRate).toFixed(6);
    return tonValue.toFixed(6);
};

const convertDollarToStars = (dollars) => Math.round(dollars / 0.02);

export {
    decodeQueryString,
    writeFile,
    formatBytes,
    getTokenData,
    htmlDecode,
    displayNotification,
    convertDollarToTon,
    convertDollarToStars,
};
