import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appPrepared: false,
    viewportHeight: null,
    userInfo: {},
    counterStopped: false,
    boosts: {},
    friends: [],
    totalFriends: 0,
    linkNext: null,
    tasks: [],
    achieves: [],
    remindedFriends: [],
    currentPage: 0,
    totalPages: 0,
    referralTokens: 0,
    userPhoto: '',
    tonAmount: 0,
    error: null,
    products: null,
    openResumeCollectorModal: false,
    isPaymentLoading: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setAppPrepared: (state, action) => {
            state.appPrepared = action.payload;
        },
        setViewportHeight: (state, action) => {
            state.viewportHeight = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setBoosts: (state, action) => {
            state.boosts = action.payload;
        },
        setFriends: (state, action) => {
            state.friends = action.payload;
        },
        setTotalFriends: (state, action) => {
            state.totalFriends = action.payload;
        },
        setLinkNext: (state, action) => {
            state.linkNext = action.payload;
        },
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setAchieves: (state, action) => {
            state.achieves = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setReferralTokens: (state, action) => {
            state.referralTokens = action.payload;
        },
        setRemindedFriends: (state, action) => {
            state.remindedFriends.push(action.payload);
        },
        setTonAmount: (state, action) => {
            state.tonAmount = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setOpenResumeCollectorModal: (state, action) => {
            state.openResumeCollectorModal = action.payload;
        },
        setIsPaymentLoading: (state, action) => {
            state.isPaymentLoading = action.payload;
        },
    },
});

export const {
    setAppPrepared,
    setViewportHeight,
    setUserInfo,
    setBoosts,
    setFriends,
    setTotalFriends,
    setTasks,
    setCurrentPage,
    setTotalPages,
    setReferralTokens,
    setRemindedFriends,
    setAchieves,
    setTonAmount,
    setLinkNext,
    setError,
    setProducts,
    setOpenResumeCollectorModal,
    setIsPaymentLoading
} = commonSlice.actions;

export default commonSlice.reducer;
