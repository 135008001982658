import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './Background.scss';

const Background = () => {
    return (
        <div className='container'>
            <div className="area">
                <ul className="stars">
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                </ul>
            </div>
            <div
                className="background"
                /*style={{
                    height: smallSizePhone ? '300px' : '450px',
                    width: smallSizePhone ? '300px' : '450px',
                }}*/
            ></div>
            <div className="blur"></div>
        </div>
    );
};

export default Background;
